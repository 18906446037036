import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isMobile: window?.innerWidth <= 768,
  innerHeight: window?.innerHeight || '100vh',
  lastScrollPositionPage: {},
  activeModals: [],
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setLastScrollPositionPage: (state, action) => {
      const {pageName, scrollPosition} = action.payload;
      state.lastScrollPositionPage[pageName] = scrollPosition;
    },
    setModal: (state, action) => {
      const {modal} = action.payload;
      state.activeModals = [...state.activeModals, modal];
    },
    deleteModal: (state, action) => {
      const {modal} = action.payload;
      state.activeModals = state?.activeModals?.filter(item => item !== modal);
    },
    setResize: (state, action) => {
      const {innerWidth, innerHeight} = action.payload;
      state.innerHeight = innerHeight;
      state.isMobile = innerWidth <= 768;
    },
  }
});

export const {
  setLastScrollPositionPage,
  setModal,
  deleteModal,
  setResize,
} = layoutSlice.actions;

export default layoutSlice.reducer;
