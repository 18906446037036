import {USER_API} from "./modules/userApi";
import {CATEGORY_API} from "./modules/categoryApi";
import {LOCATION_API} from "./modules/locationApi";
import {BOOKING_API} from "./modules/bookingApi";
import {PROPERTY_API} from "./modules/propertyApi";
import {TOUR_API} from "./modules/tourApi";
import {NOTIFICATION_API} from "./modules/notificationApi";
import {REVIEW_API} from "./modules/reviewApi";
import {FILES_API} from "./modules/files";
import {CAR_API} from "./modules/carApi";
import {PAYMENT_API} from "./modules/paymentApi";

export const API = {
  USER: USER_API,
  CATEGORY: CATEGORY_API,
  LOCATION: LOCATION_API,
  BOOKING: BOOKING_API,
  PROPERTY: PROPERTY_API,
  CAR: CAR_API,
  TOUR: TOUR_API,
  NOTIFICATION: NOTIFICATION_API,
  REVIEW: REVIEW_API,
  FILES: FILES_API,
  PAYMENT: PAYMENT_API,
};