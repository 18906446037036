import {axiosInstance} from "../../../config/axiosInstance";
import {CONSTANTS} from "../../../constants/constants";

export const LOCATION_API = {
  getArea: async () => (
    await axiosInstance.get(`${CONSTANTS?.API?.API_VERSION?.V1}/location/area`)
  ),
  getCities: async () => (
    await axiosInstance.get(`${CONSTANTS?.API?.API_VERSION?.V1}/location/cities/list`)
  ),
};