import {axiosInstance} from "../../../config/axiosInstance";
import {CONSTANTS} from "../../../constants/constants";

export const PAYMENT_API = {
  getStatus: async () => (
    await axiosInstance.get(`${CONSTANTS?.API?.API_VERSION?.V1}/payment/status`)
  ),
  getCurrencies: async () => (
    await axiosInstance.get(`${CONSTANTS?.API?.API_VERSION?.V1}/payment/currencies`)
  ),
};