export const VALUE_CONSTANTS = {
  RENTAL_TYPES: {
    PROPERTY: 'PROPERTY',
    CAR: 'CAR',
    TOUR: 'TOUR',
  },
  ACCESS_TOKEN: 'accessToken',
  COUNTRY: 'country',
  CURRENCY: 'currency',
  LANGUAGES: 'language',
  UTC_FORMAT: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
  URL_FORMAT: 'DD-MM-YYYY',
};