const SUPPORTED_PROTOCOLS = ['https:', 'http:'];
const CLOUDFLARE_ACCOUNT_ID = 'DFySbGARKFDYSW_EywLfqQ';

const extractIdFromUrl = (url) => {
  try {
    const urlObject = new URL(url);

    if (SUPPORTED_PROTOCOLS.includes(urlObject.protocol)) {
      const pathSegments = urlObject.pathname.split('/');
      return pathSegments[2];
    }
  } catch (error) {}

  return url;
};

const formatOptions = (options) => {
  if (typeof options !== 'object' || options === null) {
    return '';
  }

  return Object.entries(options)
    .map(([key, value]) => `${key}=${value}`)
    .join(',');
};

export const collectImageUrl = ({ id, options }) => {
  const finalId = extractIdFromUrl(id);
  const optionsString = formatOptions(options);

  return `https://imagedelivery.net/${CLOUDFLARE_ACCOUNT_ID}/${finalId}/${optionsString}`;
};