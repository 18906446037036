import {axiosInstance} from "../../../config/axiosInstance";
import {CONSTANTS} from "../../../constants/constants";

export const CATEGORY_API = {
  getCategory: async ({type}) => (
    await axiosInstance.get(`${CONSTANTS?.API?.API_VERSION?.V1}/category`, { params: {type} })
  ),
  // getCategoryId: async ({category_id}) => (
  //   await axiosInstance.get(`${CONSTANTS?.API?.API_VERSION?.V1}/category/${category_id}`)
  // ),
};