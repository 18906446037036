export const API_CONSTANTS = {
  API_VERSION: {
    V1: 'v1',
  },
  STATUS_CODE: {
    SUCCESS: 200,
    UNAUTHORIZED: 211,
  },
  ACCESS_TOKEN: 'accessToken',
  CURRENCY: 'currency',
  FILE_UPLOAD_TYPE: {
    MARKET_LEAD: 1,
    WEBSITE_IMAGES: 2,
    PROPERTY: 3,
    CAR: 4,
    TOUR: 5,
    CITY: 6,
    USERS_PROFILE: 7,
    USERS_DOCUMENTS: 8
  },
  BOOKING: {
    FOR: {
      MYSELF: 'MYSELF',
      SOMEONE: 'SOMEONE',
    },
    PAYMENT_MODE: {
      CASH: 1,
      CARD: 2,
      CRYPTO: 3,
    },
  },
  GUESTS: {
    ADULTS: 1,
    INFANTS: 2,
    CHILDREN: 3,
  },
  PROFILE_BOOKINGS_FILTERS: {
    ACTIVE: 'ACTIVE',
    UPCOMING: 'UPCOMING',
    PAST: 'PAST',
    PROPERTY: 'PROPERTY',
    CAR: 'CAR',
    TOUR: 'TOUR',
  },
};