import {useCallback, useEffect, useMemo, useState} from "react";
import {debounce} from "lodash";
import {collectImageUrl} from "../../utils/imageUrlParser";

const MAX_PIXELS = 100_000_000;

const useResponsiveImage = (imageId, boxRef, initial, options, scaleFactor) => {
  const [main, setMain] = useState('');
  const [blur, setBlur] = useState('');
  const [error, setError] = useState('');

  const devicePixelRatio = useMemo(() => (window.devicePixelRatio >= 2) ? 2 : window.devicePixelRatio, []);

  const adjustDimensions = useCallback(({width, height, dpr}) => {
    const scaledWidth = width * dpr;
    const scaledHeight = height * dpr;
    const totalPixels = scaledWidth * scaledHeight;

    if (totalPixels > MAX_PIXELS) {
      const scaleFactor = Math.sqrt(MAX_PIXELS / totalPixels);

      return {
        width: Math.floor(width * scaleFactor),
        height: Math.floor(height * scaleFactor)
      };
    }
    return { width, height };
  }, []);

  useEffect(() => {
    const updateImageUrl = () => {
      if (boxRef.current && imageId) {
        const boxWidth = boxRef.current.clientWidth;
        const boxHeight = boxRef.current.clientHeight;

        const {
          width: adjustedWidth,
          height: adjustedHeight
        } = adjustDimensions({
          width: boxWidth * scaleFactor,
          height: boxHeight * scaleFactor,
          dpr: devicePixelRatio
        });

        setMain(collectImageUrl({
          id: imageId,
          options: {
            w: adjustedWidth,
            h: adjustedHeight,
            dpr: devicePixelRatio,
            fit: 'cover',
            ...(options && {...options}),
          },
        }));

        setBlur(collectImageUrl({
          id: imageId,
          options: {
            w: boxWidth,
            h: boxHeight,
            dpr: 1,
            fit: 'cover',
            blur: 20,
          },
        }));

        setError(
          collectImageUrl({
            id: initial,
            options: {
              w: boxWidth,
              h: boxHeight,
              dpr: devicePixelRatio,
              fit: 'cover',
            },
          })
        );
      }
    };

    updateImageUrl();

    const debouncedUpdateImageUrl = debounce(updateImageUrl, 300);

    window.addEventListener("resize", debouncedUpdateImageUrl);

    return () => {
      window.removeEventListener("resize", debouncedUpdateImageUrl);
    };
  }, [imageId, boxRef, initial, options, adjustDimensions, scaleFactor, devicePixelRatio]);

  return {main, blur, error};
};

export default useResponsiveImage;