import React from "react";
import {Router} from "./routes/Router";
import {useAppInitialization} from "./useAppInitialization";

//global-styles
import './assets/fonts/Stylesheet.css';
import './styles/global-styles.css';
import './styles/global-variables.css';
import './styles/global-presets.css';
import './styles/global-serach-options.css';

//swiper-global-styles
import 'swiper/css'; //test
import 'swiper/css/bundle'; //test

//react-date-range-global-styles
import './styles/react-date-range/react-date-range-custom-style.css';
import './styles/react-date-range/react-date-range-custom-theme.css';

export const App = () => {

  useAppInitialization();

  return <Router />;
};