import {axiosInstance} from "../../../config/axiosInstance";
import {CONSTANTS} from "../../../constants/constants";

export const REVIEW_API = {
  getReviewUser: async ({limit, offset}) => (
    await axiosInstance.get(
      `${CONSTANTS?.API?.API_VERSION?.V1}/review/user`,
      { params: { limit, offset } }
    )
  ),
};