import {memo, useCallback, useMemo, useState} from "react";
import styles from "./MobileNavItem.module.css";

const SHOW_DURATION = 0.7;

export const MobileNavItemHoc = memo(({
  children,
  callback,
  className,
}) => {

  const [isShow, setIsShow] = useState(false);
  const [positionShow, setPositionShow] = useState({ x: 0, y: 0 });

  const onShow = useCallback((e) => {
    const targetCoords = e?.currentTarget?.getBoundingClientRect();
    const x = e?.clientX - targetCoords?.left;
    const y = e?.clientY - targetCoords?.top;
    setPositionShow({ x, y });
    setIsShow(false);
    setTimeout(() => setIsShow(true), 0);
  }, [setPositionShow, setIsShow]);

  const eventClick = useCallback((e) => {
    callback();
    onShow(e);
  }, [callback, onShow]);

  const styledShowBlock = useMemo(() => ({
    borderRadius: '50%',
    animationDuration: SHOW_DURATION + 's',
    background: '#e8973259',
    top: positionShow.y,
    left: positionShow.x,
  }), [positionShow]);

  return (
    <button
      className={className ? className : null}
      onClick={(e) => eventClick(e)}
    >
      <div
        className={`${styles.showBlock} ${isShow && styles.showBlockOn}`}
        style={styledShowBlock}
      />
      {children}
    </button>
  );
});