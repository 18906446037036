import {shallowEqual, useSelector} from "react-redux";
import {Outlet, useLocation} from "react-router-dom";
import {Suspense, useMemo} from "react";
import {LoadingScreen} from "../components/LoadingScreen/LoadingScreen";
import {MobileNav} from "../components/MobileNav/MobileNav";
import {CONSTANTS} from "../constants/constants";
import styles from "./Layout.module.css";

export const Layout = () => {

  const {pathname} = useLocation();
  const {isMobile} = useSelector(state => state.layout, shallowEqual);

  const isMobileNavVisible = useMemo(() => {
    return (
      isMobile &&
      [
        CONSTANTS?.ROUTES?.HOME?.path,
        CONSTANTS?.ROUTES?.PROPERTY?.path,
        CONSTANTS?.ROUTES?.CAR?.path,
        CONSTANTS?.ROUTES?.TOUR?.path,
        CONSTANTS?.ROUTES?.PROFILE?.path,
        CONSTANTS?.ROUTES?.AUTH?.path,
      ].includes(pathname)
    );
  }, [isMobile, pathname]);

  return (
    <div className={styles.wrapper}>
      <Suspense fallback={<LoadingScreen />}>
        <Outlet context={{pathname}}/>
      </Suspense>
      {(isMobileNavVisible && isMobile) && <MobileNav pathname={pathname} />}
    </div>
  )
};