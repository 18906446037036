import axios from 'axios';
import {localStorageService} from "../services/localStorageService";
import {store} from '../redux/store';
import {logoutUserThunk} from "../redux/thunks/authThunk";
import {CONSTANTS} from "../constants/constants";

export const axiosInstance = axios.create({
  baseURL: 'https://socket.rentvip.com/api',
  headers: {
    Accepted: 'application/json',
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorageService.get(CONSTANTS?.VALUE?.ACCESS_TOKEN);
    if (!!token && !!config?.headers) {
      config.headers.authorization = token;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response?.data?.statusCode === CONSTANTS?.API?.STATUS_CODE?.UNAUTHORIZED) {
      store.dispatch(logoutUserThunk());
    }
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      store.dispatch(logoutUserThunk());
    }
    return Promise.reject(error);
  }
);