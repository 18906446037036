import {axiosInstance} from "../../../config/axiosInstance";
import {CONSTANTS} from "../../../constants/constants";

export const USER_API = {
  setLogin: async ({phoneNumber, voucher}) => (
    await axiosInstance.post(
      `${CONSTANTS?.API?.API_VERSION?.V1}/user/login`,
      {phoneNumber, voucher}
    )
  ),
  setOPT: async ({otp, phoneNumber, edit, type}) => (
    await axiosInstance.post(
      `${CONSTANTS?.API?.API_VERSION?.V1}/user/verify/otp`,
      {otp, phoneNumber, edit, type}
    )
  ),
  getOPT: async ({phoneNumber, type}) => (
    await axiosInstance.put(
      `${CONSTANTS?.API?.API_VERSION?.V1}/user/resend/otp`,
      {phoneNumber, type}
    )
  ),
  getUser: async () => await axiosInstance.get(`${CONSTANTS?.API?.API_VERSION?.V1}/user`),
  setRegister: async ({firstName, lastName, phoneNumber, email, dateOfBirth, documents, voucher}) => (
    await axiosInstance.post(
      `${CONSTANTS?.API?.API_VERSION?.V1}/user/register`,
      {firstName, lastName, phoneNumber, email, dateOfBirth, documents, voucher}
    )
  ),
  updateUser: async ({firstName, lastName, phoneNumber, email, image, dateOfBirth, documents, passport}) => (
    await axiosInstance.patch(
      `${CONSTANTS?.API?.API_VERSION?.V1}/user`,
      {firstName, lastName, phoneNumber, email, image, dateOfBirth, documents, passport}
    )
  ),
  getHome: async ({
      type,
      categoryId,
      minPrice,
      maxPrice,
      limit,
      page,
      checkIn,
      checkOut,
      currency,
      areaId,
      sortByPrice,
      bedRooms,
      guests,
    }) => (
    await axiosInstance.get(
      `${CONSTANTS?.API?.API_VERSION?.V1}/user/home`,
      {params: {
        type,
        categoryId,
        minPrice,
        maxPrice,
        limit,
        page,
        checkIn,
        checkOut,
        currency,
        areaId,
        sortByPrice,
        bedRooms,
        guests,
      }}
    )
  ),
};
