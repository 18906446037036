import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  phoneNumber: null,
  otp: null,
  activeSlide: 0,
  isLoading: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setPhoneNumber (state, action) {
      state.phoneNumber = action.payload;
    },
    setOtp (state, action) {
      state.otp = action.payload;
    },
    setActiveSlide (state, action) {
      state.activeSlide = action.payload;
    },
    setIsLoading (state, action) {
      state.isLoading = action.payload;
    },
    resetAuthState (state) {
      state.phoneNumber = initialState.phoneNumber;
      state.otp = initialState.otp;
      state.activeSlide = initialState.activeSlide;
      state.isLoading = initialState.isLoading;
    },
  }
});

export const {
  setPhoneNumber,
  setOtp,
  setActiveSlide,
  setIsLoading,
  resetAuthState,
} = authSlice.actions;

export default authSlice.reducer;