import {getCurrencyByCountry, getLanguageByCountry} from "../../utils/localizationUtils";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {setCountry, setCurrency, setDataPeople, setLanguage} from "../slices/userSlice";
import {API} from "../../services/api/api";
import {CONSTANTS} from "../../constants/constants";
import {localStorageService} from "../../services/localStorageService";
import i18n from "i18next";

//----------------------------------------------------------------------------------------------------------------------

export const getUserThunk = createAsyncThunk(
  'user/getUser',
  async (_, { dispatch }) => {
    try {
      const res = await API.USER.getUser();
      if (res?.data?.statusCode === CONSTANTS?.API?.STATUS_CODE?.SUCCESS) {
        dispatch(setDataPeople({ dataPeople: res?.data?.data }));
      }
    } catch (error) {
      throw Error(error);
    }
  }
);

//----------------------------------------------------------------------------------------------------------------------

const fetchCountry = async () => {
  try {
    const response = await fetch('/');
    if (response.ok) {
      return response.headers.get('X-Country-Code') || null;
    }
  } catch (error) {
    console.error('Error fetching country from header:', error);
  }

  return CONSTANTS?.CONFIG?.COUNTRY_DEFAULT;
};

//----------------------------------------------------------------------------------------------------------------------

export const initializeCountryThunk = createAsyncThunk(
  'user/getCountry',
  async (_, { dispatch }) => {
    try {
      const country = await fetchCountry();
      if (country) {
        dispatch(setCountry({ country }));
      }
    } catch (error) {
      console.error('Error initializing country:', error);
      dispatch(setCountry({ country: CONSTANTS?.CONFIG?.COUNTRY_DEFAULT }));
    }
  }
);

//----------------------------------------------------------------------------------------------------------------------

export const initializeCurrencyThunk = createAsyncThunk(
  'user/getCurrency',
  async (_, { dispatch }) => {
    try {
      const country = await fetchCountry();
      const currency = getCurrencyByCountry(
        country,
        CONSTANTS?.CONFIG?.CURRENCY?.AVAILABLE,
        CONSTANTS?.CONFIG?.CURRENCY?.DEFAULT,
      );
      dispatch(setCurrencyThunk({ currency }));
    } catch (error) {
      console.error('Error initializing currency:', error);
      dispatch(setCurrencyThunk({ currency: CONSTANTS?.CONFIG?.CURRENCY?.DEFAULT }));
    }
  }
);

export const setCurrencyThunk = createAsyncThunk(
  'user/setCurrency',
  async ({ currency }, { dispatch }) => {
    try {
      dispatch(setCurrency({ currency }));
      localStorageService.set(CONSTANTS?.VALUE?.CURRENCY, currency);
    } catch (error) {
      throw Error(error);
    }
  }
);

//----------------------------------------------------------------------------------------------------------------------

export const initializeLanguageThunk = createAsyncThunk(
  'user/getLanguage',
  async (_, { dispatch }) => {
    try {
      const country = await fetchCountry();
      const language = getLanguageByCountry(
        country,
        CONSTANTS?.CONFIG?.LANGUAGES?.AVAILABLE,
        CONSTANTS?.CONFIG?.LANGUAGES?.DEFAULT,
      );
      dispatch(setLanguageThunk({ language }));
    } catch (error) {
      console.error('Error initializing language:', error);
      dispatch(setLanguageThunk({ language: CONSTANTS?.CONFIG?.LANGUAGES?.DEFAULT }));
    }
  }
);

export const setLanguageThunk = createAsyncThunk(
  'user/setLanguage',
  async ({ language }, { dispatch }) => {
    try {
      await i18n.changeLanguage(language.VALUE);
      document.documentElement.lang = i18n.language;
      const isRtl = CONSTANTS?.CONFIG?.LANGUAGES?.AVAILABLE[language.TYPE]?.['isRTL'];
      document.documentElement.dir = isRtl ? 'rtl' : 'ltr';

      dispatch(setLanguage({ language }));
      localStorageService.set(CONSTANTS?.VALUE?.LANGUAGES, language);
    } catch (error) {
      throw Error(error);
    }
  }
);