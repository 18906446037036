import {axiosInstance} from "../../../config/axiosInstance";
import {CONSTANTS} from "../../../constants/constants";

export const FILES_API = {
  setFile: async ({file, type = CONSTANTS?.API?.FILE_UPLOAD_TYPE?.WEBSITE_IMAGES}) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", type);

    return await axiosInstance.post(`${CONSTANTS?.API?.API_VERSION?.V1}/files/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
};