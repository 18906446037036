import {CONFIG_CONSTANTS} from "./modules/configConstants";
import {ROUTES_CONSTANTS} from "./modules/routesConstants";
import {API_CONSTANTS} from "./modules/apiConstants";
import {IMAGES_CONSTANTS} from "./modules/imagesConstants";
import {VALUE_CONSTANTS} from "./modules/valueConstants";

export const CONSTANTS = {
  CONFIG: CONFIG_CONSTANTS,
  API: API_CONSTANTS,
  IMAGES: IMAGES_CONSTANTS,
  VALUE: VALUE_CONSTANTS,
  ROUTES: ROUTES_CONSTANTS,
};