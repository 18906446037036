import {createAsyncThunk} from '@reduxjs/toolkit';
import {localStorageService} from "../../services/localStorageService";
import {resetAuthState, setActiveSlide, setIsLoading, setOtp, setPhoneNumber} from "../slices/authSlice";
import {API} from "../../services/api/api";
import {CONSTANTS} from "../../constants/constants";
import {setIsAuth} from "../slices/userSlice";

export const logoutUserThunk = createAsyncThunk(
  'auth/logout',
  async (_, { dispatch }) => {
    try {
      localStorageService.remove(CONSTANTS?.VALUE?.ACCESS_TOKEN);
      dispatch(setIsAuth(false));
      dispatch(resetAuthState());
    } catch (error) {
      throw Error(error);
    }
  }
);

export const authorizeUserThunk = createAsyncThunk(
  'auth/authorize',
  async ({ accessToken }, { dispatch }) => {
    try {
      localStorageService.set(CONSTANTS?.VALUE?.ACCESS_TOKEN, accessToken);
      dispatch(setIsAuth(true));
      dispatch(resetAuthState());
    } catch (error) {
      throw Error(error);
    }
  }
);

export const setLoginThunk = createAsyncThunk(
  'auth/setLogin',
  async ({ phoneNumber }, { dispatch }) => {
    dispatch(setIsLoading(true));

    try {
      const res = await API.USER.setLogin({ phoneNumber });

      if (res?.data?.statusCode === CONSTANTS?.API?.STATUS_CODE?.SUCCESS) {
        dispatch(setPhoneNumber(phoneNumber));
        dispatch(setOtp(res?.data?.data?.otp));
        dispatch(setActiveSlide(1));
      }

      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(setIsLoading(false));
      throw new Error(error);
    }
  }
);

export const setOtpThunk = createAsyncThunk(
  'auth/setOtp',
  async ({ otp, phoneNumber }, { dispatch }) => {
    dispatch(setIsLoading(true));

    try {
      const res = await API.USER.setOPT({ otp: otp, phoneNumber, type: 1, edit: false });

      if (res?.data?.statusCode === CONSTANTS?.API?.STATUS_CODE?.SUCCESS) {
        if (res?.data?.data?.user) {
          dispatch(authorizeUserThunk({ accessToken: res?.data?.data?.user?.accessToken }));
        } else {
          dispatch(setActiveSlide(2));
        }
      }

      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(setIsLoading(false));
      throw new Error(error);
    }
  }
);

export const setResendOtpThunk = createAsyncThunk(
  'auth/setResendOtp',
  async ({ phoneNumber }, { dispatch }) => {
    dispatch(setIsLoading(true));

    try {
      const res = await API.USER.getOPT({ phoneNumber, type: 1});
      if (res?.data?.statusCode === CONSTANTS?.API?.STATUS_CODE?.SUCCESS) {
        dispatch(setOtp(res?.data?.data?.otp));
      }
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(setIsLoading(false));
      throw new Error(error);
    }
  }
);

export const setRegisterThunk = createAsyncThunk(
  'auth/setRegister',
  async ({ dataForm, phoneNumber }, { dispatch }) => {
    dispatch(setIsLoading(true));

    const {dateOfBirth, email, firstName, lastName} = dataForm;

    try {
      const res = await API.USER.setRegister({
        firstName,
        lastName,
        phoneNumber,
        email,
        dateOfBirth,
        documents: {
          passport: [
            'string'
          ]
        }
      });

      if (res?.data?.statusCode === CONSTANTS?.API?.STATUS_CODE?.SUCCESS) {
        dispatch(authorizeUserThunk({ accessToken: res?.data?.data?.accessToken}))
      }
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(setIsLoading(false));
      throw new Error(error);
    }
  }
);