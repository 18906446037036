export const IMAGES_CONSTANTS = {
  INITIAL: '0ba1dffc-9088-4c94-a19d-3160c313f900',
  HOME_BANNER_CAROUSEL: {
    PROPERTY: 'fa10c4eb-fc9b-425d-d5a0-176965b56700',
    CAR: '3aa26a12-582c-49c4-c583-1d3f6e12f700',
    // 0db6a6fd-ca28-40ec-22ed-7f2d89437600
    TOUR: '1605c99a-b3be-4cf0-08be-acaf18cf8000',
  },
  NATIVE_APP_PROMO: {
    APPLE_STORE: 'fe96977e-5807-4214-e674-2ee01dc58f00',
    GOOGLE_PLAY: '0662338a-105b-4d72-2259-cad5f3fc9400',
    TEL_LEFT: '3ce61297-731f-42ef-d3ed-1f873a91c700',
    TEL_RIGHT: 'fa9ada44-1fb7-4ae7-8226-4c73544a5a00',
  },
  ABOUT_US_SERVICE: {
    PROPERTY: 'aab5c97d-54b8-4688-55af-e3cb2d746500',
    CAR: '6a8c9dbe-2167-487e-8dd4-44084d505900',
    TOUR: '5fe32180-a4d4-42af-fb4b-72eee08cc200',
    DESIGNER: '2f1deb69-a1c2-42db-9e09-5961d0db4800',
  },
  ABOUT_US_PROMO: {
    TOP: [
      "5760d1e1-adce-42c7-44a1-128800481500",
      "464d3ff0-2a99-45a7-23c7-5748cf9f1a00",
      "34336832-4b68-448a-b9a2-0317c0364000",
      "8b2f43de-07a0-4455-54c6-cc06595e4400",
      "33216774-c914-411e-c95a-1ed9ccc5ba00",
      "916c73a1-c2dc-420b-d9ae-3dfcec5dc600",
    ],
    BOTTOM: [
      "e236d8c2-a652-45cc-29e7-485f62341c00",
      "be20777c-dc98-4dfc-af60-eedc6b8db500",
      "04028c69-c603-4514-8115-781ae6a09a00",
      "2d0735f6-bee5-478c-69e8-ff0f20c14e00",
      "47068ef7-4de4-4a20-30f2-0ec761008a00",
      "69e87797-4fa6-4258-8d6b-64cc678b2300",
    ],
  },
};
