import {axiosInstance} from "../../../config/axiosInstance";
import {CONSTANTS} from "../../../constants/constants";

export const PROPERTY_API = {
  getPropertyDetails: async ({slug, currency}) => (
    await axiosInstance.get(
      `${CONSTANTS?.API?.API_VERSION?.V1}/property/details`,
      { params: {slug, currency} },
    )
  ),
  getAvailability: async ({propertyId, currency}) => (
    await axiosInstance.get(
      `${CONSTANTS?.API?.API_VERSION?.V1}/property/check/availiablity`,
      { params: {propertyId, currency} }
    )
  ),
  getRecommended: async ({propertyId, limit, offset, currency}) => (
    await axiosInstance.get(
      `${CONSTANTS?.API?.API_VERSION?.V1}/property/recommended`,
      { params: {propertyId, limit, offset, currency} }
    )
  ),
  checkPromotion: async ({propertyId, checkInDate, checkOutDate}) => (
    await axiosInstance.get(
      `${CONSTANTS?.API?.API_VERSION?.V1}/property/check/promotion`,
      { params: {propertyId, checkInDate, checkOutDate } }
    )
  ),
  getPromotionListUser: async ({propertyId, status}) => (
    await axiosInstance.get(
      `${CONSTANTS?.API?.API_VERSION?.V1}/promotion/property/list/user`,
      { params: {propertyId, status} }
    )
  ),
};