import {axiosInstance} from "../../../config/axiosInstance";
import {CONSTANTS} from "../../../constants/constants";

export const TOUR_API = {
  getTourDetails: async ({slug, currency}) => (
    await axiosInstance.get(`${CONSTANTS?.API?.API_VERSION?.V1}/tour/${slug}/${currency}`)
  ),
  // getDropdown: async (params) => (
  //   await axiosInstance.get(`${CONSTANTS?.API?.API_VERSION?.V1}/tour/dropdown`, {params})
  // ),
  // getList: async ({latitude, longitude, categoryId, currency, subCategories}) => (
  //   await axiosInstance.get(`${CONSTANTS.API.API_VERSION.V1}/tour/searchLists`, { params: {
  //     latitude: 30.7046,
  //     longitude: 76.7179,
  //     categoryId: '644146688fbabb7b0b417f0c',
  //     currency: 'USD',
  //     subCategories: ['64426093e67adcfd81babe99'],
  //   }})
  // ),
};