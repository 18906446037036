import React from 'react';
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import {Layout} from "../layout/Layout";
import {authRoutes} from "./modules/authRoutes";
import {publicRoutes} from "./modules/publicRoutes";

export const Router = () => {
  return (
    <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
      <Routes>
        <Route element={<Layout />}>
          {Object.entries(publicRoutes).map(([path, Component]) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
          {Object.entries(authRoutes).map(([path, Component]) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};