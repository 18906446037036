import {CONSTANTS} from "../../constants/constants";
import {lazy} from "react";
import {withAuth} from "../../hocs/withAuth";

const Profile = lazy(() => import("../../pages/Profile/Profile"));
const BookingDetails = lazy(() => import("../../pages/booking/BookingDetails/BookingDetails"));
const BookingPaymentResult = lazy(() => import("../../pages/booking/BookingPaymentResult/BookingPaymentResult"));

export const authRoutes = {
  [CONSTANTS?.ROUTES?.PROFILE?.path + '/*']: withAuth(Profile),
  [CONSTANTS?.ROUTES?.BOOKING_DETAILS?.path]: withAuth(BookingDetails),
  'payment/result': withAuth(BookingPaymentResult),
};